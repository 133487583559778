import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

export const AnyChartSimplifiedFigure3 = ({
	years,
	data,
	topicName,
	segmentationName,
}) => {
	const anyChartSimplifiedFigure3 = useRef(null);

	useEffect(() => {
		const chart = anychart;

		let dataSet = chart.data.set(data);

		// DATA MAP
		let SeriesFYLY = dataSet.mapAs({ x: 0, value: 1 });

		let radarChart = chart.radar();

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		// THEMES
		chart.theme("marketForecast");

		const currentYear = new Date().getFullYear();

		// CREDITS
		radarChart
			.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		radarChart.defaultSeriesType("area");

		// SET DATA
		radarChart.data(SeriesFYLY);

		// Title
		radarChart
			.title()
			.enabled(true)
			.useHtml(true)
			.padding([0, 0, 10, 0])
			.text(
				`<span style="font-size: 16px;font-weight:bold;">${topicName} Market CAGR by ${segmentationName}</span>` +
					'<br/><span  style="color:#929292; font-size: 12px;">' +
					`CAGR ${years[0]}-${years[1]}</span>`
			);

		// RADARCHART PADDINGS
		radarChart.padding(20, 5, 5, 5);

		// SERIES SETTIGS
		let series = radarChart.area(SeriesFYLY);

		// SERIES TOOLTIPS
		series.tooltip().title().fontColor("#FFFFFF");
		series.tooltip().title().fontSize(14);
		series.tooltip().fontColor("#FFFFFF");
		series.tooltip().fontSize(12);
		series.tooltip().padding(10);
		series.tooltip().format("CAGR: {%Value}{decimalsCount:1}%");

		// ADD LAYERS TO SERIES
		series.labels().enabled(true);
		series.labels().anchor("center-bottom");
		series.labels().format("{%Value}{decimalsCount:1}%");
		series.labels().offsetX(15);
		series.labels().padding(5);
		series.labels().background().enabled(true).fill("#FFFFFF");

		// HIDE YAXIS
		radarChart.yAxis().labels().enabled(false);

		// DRAW
		radarChart.container(anyChartSimplifiedFigure3.current).draw();

		// CONTEXT MENU
		radarChart.contextMenu().itemsFormatter(function (items) {
			items["save-chart-as"].subMenu["save-chart-as-png"].action =
				function () {
					radarChart.saveAsPng({
						width: 1000,
						height: 1000,
						quality: 0.3,
						filename: `SS03_${topicName}_Market_CAGR_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-jpg"].action =
				function () {
					radarChart.saveAsJpg({
						width: 1000,
						height: 1000,
						quality: 0.3,
						forceTransparentWhite: false,
						filename: `SS03_${topicName}_Market_CAGR_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-svg"].action =
				function () {
					radarChart.saveAsSvg({
						width: 1000,
						height: 1000,
						filename: `SS03_${topicName}_Market_CAGR_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-pdf"].action =
				function () {
					radarChart.saveAsPdf({
						paperSize: "a4",
						landscape: true,
						filename: `SS03_${topicName}_Market_CAGR_by_${segmentationName}`,
					});
				};
			delete items["about"];
			delete items["share-with"];
			return items;
		});

		// EXPORT NAME
		chart.exports.filename(
			`SS03_${topicName}_Market_CAGR_by_${segmentationName}`
		);
		return () => {
			radarChart.dispose();
		};
	}, [data, topicName, segmentationName, years]);

	return (
		<div
			ref={anyChartSimplifiedFigure3}
			style={{ width: "600px", height: "450px" }}
		/>
	);
};
