import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

export const AnyChartSimplifiedFigure1 = ({
	years,
	data,
	maxValue,
	valueDisplayOption,
	topicName,
	segmentationName,
}) => {
	const anyChartSimplifiedFigure1Ref = useRef(null);

	useEffect(() => {
		let chart = anychart;

		const newData = data.map((subArray) => {
			let lastIndex = subArray.length - 1;
			subArray[lastIndex] = subArray[lastIndex]
				.toString()
				.replace("%", "");
			return subArray;
		});

		let dataSet = chart.data.set(newData);

		// MAP DATA
		let seriesFY = dataSet.mapAs({ x: 0, value: 1 });
		let seriesLY = dataSet.mapAs({ x: 0, value: 2 });
		let seriesCAGR = dataSet.mapAs({ x: 0, value: 3 });

		// CREATE COLUMN CHART
		let columnChart = chart.column();

		// THEMES
		chart.theme("marketForecast");

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		const currentYear = new Date().getFullYear();

		// CREDITS
		columnChart
			.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		// COLUMNS PROPERTIES
		columnChart.padding(20, 5, 5, 5);

		// column title // hide column Title
		columnChart
			.title()
			.enabled(true)
			.useHtml(true)
			.padding([0, 0, 10, 0])
			.text(
				`<span style="font-size: 16px;font-weight:bold;">${topicName} Market Summary by ${segmentationName}</span>` +
					'<br/><span  style="color:#929292; font-size: 12px;">' +
					`Market Size ${years[0]}, Market Size ${years[1]}, CAGR ${years[0]}-${years[1]}</span>`
			);

		// GRID SETTINFGS
		columnChart.yGrid(true).xGrid(true).xMinorGrid(true).yMinorGrid(true);

		// LEGENDS SETTINGS
		columnChart.legend().enabled(true).padding({ top: 10, bottom: 30 });
		columnChart.legend().position("bottom");

		// YAXIS
		columnChart
			.yAxis(0)
			.title(`Market size in US$ ${valueDisplayOption}`)
			.title()
			.padding(10)
			.fontColor("#385D8A");

		columnChart
			.yAxis(0)
			.labels()
			.format(function () {
				return anychart.format.number(this.value, {
					groupsSeparator: ",",
				});
			});

		columnChart
			.yAxis(1)
			.title(`CAGR ${years[0]}-${years[1]} - Percentage`)
			.title()
			.padding(10)
			.fontColor("#003A70");

		columnChart.yAxis(1).labels().format("{%Value}{decimalsCount:1}%");

		// Grid setting
		columnChart.xGrid(true);
		columnChart.yGrid(true);

		// ADDING EXTRA Y SCALE
		let extraYScale = chart.scales.linear();
		extraYScale.minorTicks().interval(2);

		// ADDING AND ADJUST EXTRA YAXIS
		let extraYAxis = columnChart.yAxis(1);
		extraYAxis.orientation("right");
		extraYAxis.scale(extraYScale);

		// setting and adjusting data visualizzation
		let columnSeriesFY = columnChart.column(seriesFY);
		columnSeriesFY.name(`${years[0]}`);

		let columnSeriesLY = columnChart.column(seriesLY);
		columnSeriesLY.name(`${years[1]}`);

		let markerSeriesCAGR = columnChart.marker(seriesCAGR);
		markerSeriesCAGR.name(`CAGR ${years[0]}-${years[1]}`);
		markerSeriesCAGR.yScale(extraYScale);

		// Add labels to markerSeriesCAGR
		markerSeriesCAGR
			.labels()
			.enabled(true)
			.anchor("center-bottom")
			.format("{%Value}%")
			.offsetY(15)
			.padding(5)
			.background()
			.enabled(true)
			.fill("#FFFFFF");

		// set the size of markers
		markerSeriesCAGR
			.normal()
			.size(10)
			.type("circle")
			.fill("#003A70", 0.7)
			.stroke("#ffffff", 1, "", "");
		markerSeriesCAGR
			.hovered()
			.size(15)
			.type("circle")
			.fill("#003A70", 0.5)
			.stroke("#ffffff", 1, "", "");
		markerSeriesCAGR
			.selected()
			.size(15)
			.type("circle")
			.fill("#003A70", 0.9)
			.stroke("#ffffff", 1, "", "");

		columnSeriesFY
			.normal()
			.fill("#385D8A", 0.7)
			.stroke("#ffffff", 1, "", "");
		columnSeriesFY
			.hovered()
			.fill("#385D8A", 0.5)
			.stroke("#ffffff", 1, "", "");
		columnSeriesFY
			.selected()
			.fill("#385D8A", 0.9)
			.stroke("#ffffff", 1, "", "");

		columnSeriesLY
			.normal()
			.fill("#A1B4D4", 0.7)
			.stroke("#ffffff", 1, "", "");
		columnSeriesLY
			.hovered()
			.fill("#A1B4D4", 0.5)
			.stroke("#ffffff", 1, "", "");
		columnSeriesLY
			.selected()
			.fill("#A1B4D4", 0.9)
			.stroke("#ffffff", 1, "", "");

		// GLOBAL TOOLTIPS
		columnChart.tooltip().title().fontColor("#FFFFFF").fontSize(14);
		columnChart.tooltip().fontColor("#FFFFFF").fontSize(12).padding(10);

		markerSeriesCAGR.tooltip().format("CAGR: {%Value}%");

		columnSeriesFY.tooltip().format(function () {
			return (
				`Market size ${years[0]}: ` +
				anychart.format.number(this.value, {
					groupsSeparator: ",",
					decimalSeparator: ".",
				}) +
				` US$ ${valueDisplayOption}`
			);
		});

		columnSeriesLY.tooltip().format(function () {
			return (
				`Market size ${years[1]}: ` +
				anychart.format.number(this.value, {
					groupsSeparator: ",",
					decimalSeparator: ".",
				}) +
				` US$ ${valueDisplayOption}`
			);
		});

		//
		columnChart.container(anyChartSimplifiedFigure1Ref.current).draw();

		//CONTEXTMENU SETTINGS
		columnChart.contextMenu().itemsFormatter(function (items) {
			items["save-chart-as"].subMenu["save-chart-as-png"].action =
				function () {
					columnChart.saveAsPng({
						width: 1000,
						height: 1000,
						quality: 0.3,
						filename: `SS01_${topicName}_Market_summary_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-jpg"].action =
				function () {
					columnChart.saveAsJpg({
						width: 1000,
						height: 1000,
						quality: 0.3,
						forceTransparentWhite: false,
						filename: `SS01_${topicName}_Market_summary_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-svg"].action =
				function () {
					columnChart.saveAsSvg({
						width: 1000,
						height: 1000,
						filename: `SS01_${topicName}_Market_summary_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-pdf"].action =
				function () {
					columnChart.saveAsPdf({
						paperSize: "a4",
						landscape: true,
						filename: `SS01_${topicName}_Market_summary_by_${segmentationName}`,
					});
				};
			delete items["about"];
			delete items["share-with"];
			return items;
		});

		// EXPORT NAME
		chart.exports.filename(
			`SS01_${topicName}_Market_summary_by_${segmentationName}`
		);

		return () => {
			columnChart.dispose();
		};
	}, [data, topicName, segmentationName, years, valueDisplayOption]);

	return (
		<div
			ref={anyChartSimplifiedFigure1Ref}
			style={{ width: "600px", height: "450px" }}
		></div>
	);
};
