import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticationService } from "./Service";
import { useNotifications } from "../notifications/Notifications";
import { saveAs } from "file-saver";

let AuthContext = createContext({});

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	let localUser = getUserFromLocalStorage();

	let [user, setUser] = useState(localUser);
	let notifications = useNotifications();
	const navigate = useNavigate();

	let saveUser = (user) => {
		setUser(user);
		localStorage.setItem("user", JSON.stringify(user));
	};

	let signin = (username, password, callback) => {
		return notifications.promise(
			authenticationService.SignIn(username, password, (user) => {
				saveUser(user);
				callback(user);
			})
		);
	};

	let confirmSignin = (code, trustDevice, callback) => {
		return notifications.promise(
			authenticationService.ConfirmSignIn(code, trustDevice, (user) => {
				saveUser(user);
				callback();
			})
		);
	};

	let trackAction = (actionId) => {
		// Track login within this service
		// apiGet("/api/tracker");
		// return apiPost("/api/tracker/Track", {
		// 	url: window.location.pathname,
		// 	actionId: actionId,
		// });

		attemptFetch(
			"/api/tracker/Track",
			"POST",
			{
				url: window.location.pathname,
				actionId: actionId,
			},
			handleResponse
		);
	};

	let signout = () => {
		// Track logout within this service
		// apiGet("/api/tracker");
		apiPost("/api/tracker/Track", {
			url: window.location.pathname,
			actionId: 2,
		});

		saveUser(null);
		localStorage.removeItem("user");
		// notifications.success("Logout");
	};

	let apiGet = (endpoint) => {
		return attemptFetch(endpoint, "GET", null, handleResponse).catch(
			(msg) => {
				notifications.error(msg);
				return Promise.reject(msg);
			}
		);
	};

	let apiPost = (endpoint, body) => {
		return notifications.promise(
			attemptFetch(endpoint, "POST", body, handleResponse)
		);
	};

	let apiDownload = (endpoint) => {
		return attemptFetch(endpoint, "GET", null, handleDownload).catch(
			(msg) => {
				notifications.error(msg);
				return Promise.reject(msg);
			}
		);
	};

	let apiBlob = (endpoint) => {
		return attemptFetch(endpoint, "GET", null, handleBlob).catch((msg) => {
			notifications.error(msg);
			return Promise.reject(msg);
		});
	};

	let apiBlobPlusData = (endpoint) => {
		return attemptFetch(endpoint, "GET", null, handleBlobPlusData).catch(
			(msg) => {
				notifications.error(msg);
				return Promise.reject(msg);
			}
		);
	};

	let attemptFetch = (endpoint, method, body, handler, tries = 1) => {
		if (tries < 0) {
			localStorage.removeItem("user");
			navigate("/login");

			return null;
		}

		let token = user?.jwt ?? "";
		if (!token) {
			navigate("/login");

			return null;
		}

		let requestOptions = {
			method: method,
			headers: {
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			},
		};

		if (body) {
			requestOptions.body = JSON.stringify(body);
		}

		function attemptTokenRefresh() {
			authenticationService
				.RefreshToken((u) => {
					saveUser(u);
					tries = tries - 1;

					attemptFetch(endpoint, method, body, tries);
				})
				.catch(() => {
					navigate("/login");
				});
		}

		return fetch(endpoint, requestOptions)
			.then(handler)
			.catch((err) => {
				if (err instanceof UnauthorizedError) {
					return attemptTokenRefresh();
				}

				return Promise.reject(err);
			});
	};

	class UnauthorizedError extends Error {
		constructor(message) {
			super(message);
			this.name = "UnauthorizedError";
		}
	}

	function handleResponse(response) {
		return response.text().then((text) => {
			if (response.ok) {
				const data = text && JSON.parse(text);
				return data;
			}

			if (response.status == 401) {
				throw new UnauthorizedError();
			}

			const data = text && JSON.parse(text);
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		});
	}

	function handleDownload(response) {
		// for each response header, log an array with header name as key
		var fName = response.headers
			.get("content-disposition")
			.split("filename=")[1]
			.split(";")[0];

		if (!fName) {
			fName = "marketforecaststudy.pdf";
		}

		return response.blob().then((blob) => saveAs(blob, fName));
	}

	function handleBlob(response) {
		return response.blob().then((blob) => URL.createObjectURL(blob));
	}

	function handleBlobPlusData(response) {
		const fileId = response.headers.get("X-File-Id");
		console.log(fileId);
		return response.blob().then((blob) => {
			const fileURL = URL.createObjectURL(blob);
			return { fileURL, fileId };
		});
	}

	function getUserFromLocalStorage() {
		let localJSON = localStorage.getItem("user");
		if (!localJSON) {
			return null;
		}

		return JSON.parse(localJSON);
	}

	let value = {
		user,
		signin,
		confirmSignin,
		signout,
		apiGet,
		apiPost,
		apiDownload,
		apiBlob,
		trackAction,
		apiBlobPlusData,
	};
	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}
