import React from "react";
import { useState, useEffect } from "react";

// Components
import { AnyChartFigure1 } from "./components/AnyChartFigure1.jsx";
import { AnyChartFigure2 } from "./components/AnyChartFigure2.jsx";
import { AnyChartSimplifiedFigure1 } from "./components/AnyChartSimplifiedFigure1.jsx";
import { AnyChartSimplifiedFigure2 } from "./components/AnyChartSimplifiedFigure2.jsx";
import { AnyChartSimplifiedFigure3 } from "./components/AnyChartSimplifiedFigure3.jsx";
import { AnychartSimplifiedPieChart } from "./components/AnychartSimplifiedPieChart.jsx";

export const SingleSegmentationOutput = ({
	output,
	topicName,
	arrayDesc,
	valueDisplayOption,
	decimalsOption,
}) => {
	// outputViews For table
	const [segmentationTitles, setSegmentationTitles] = useState([]);
	const [headerTable, setHeaderTable] = useState([]);
	const [segmentationItemValues, setSegmentationItemValues] = useState([]);

	// totals
	const [totals, setTotals] = useState([]);

	// output For Charts
	const [toChartYears, setToChartYears] = useState([]);
	const [toChartData, setToChartData] = useState([]);
	const [toChartSegmItems, setToChartSegmItems] = useState([]);

	// simplified Table
	const [simplifiedSegmentationTitles, setSimplifiedSegmentationTitles] =
		useState([]);
	const [simplifiedHeaderTable, setSimplifiedHeaderTable] = useState([]);
	const [
		simplifiedSegmentationItemValues,
		setSimplifiedSegmentationItemValues,
	] = useState([]);

	// Totals
	const [simplifiedTotals, setSimplifiedTotals] = useState([]);

	// output For figure1 Charts
	const [toFigure1ChartYears, setToFigure1ChartYears] = useState([]);
	const [toFigure1ChartData, setToFigure1ChartData] = useState([]);
	const [toFigure1SegmItems, setToFigure1SegmItems] = useState([]);

	//output For Figure2 Charts
	const [toFigure2ChartData, setToFigure2ChartData] = useState([]);

	// output for FigureSpider Chart
	const [toFigureSpiderData, setToFigureSpiderData] = useState([]);

	// output For Figure3 Charts
	const [toFigure3ChartDataYear, setToFigure3ChartDataYear] = useState([]);
	const [toFigure3ChartDataFirstYear, setToFigure3ChartDataFirstYear] =
		useState([]);
	const [toFigure3ChartDataLastYear, setToFigure3ChartDataLastYear] =
		useState([]);

	const [toFigure3ChartFirstYear, setToFigure3ChartFirstYear] =
		useState(null);
	const [toFigure3ChartLastYear, setToFigure3ChartLastYear] = useState(null);

	const [toFigure3TotalFirstYear, setToFigure3TotalFirstYear] =
		useState(null);
	const [toFigure3TotalLastYear, setToFigure3TotalLastYear] = useState(null);

	// maxValue
	const [maxValue, setMaxValue] = useState([]);
	const [maxValueFigure1, setMaxValueFigure1] = useState([]);
	// no need for figure 2 cause we get MaxValue with the data we send

	// sync loading
	const [loadingStep, setLoadingStep] = useState(0);
	const [loadingSteps, setLoadingSteps] = useState(7);
	const [loadingFinish, setLoadingFinish] = useState(false);

	const [finish, setFinish] = useState(false);

	useEffect(() => {
		setFinish(false);
		setLoadingStep(0);
		if (output) {
			assignOutput();
			assignOutputToChart();
			// getTotalRow(); other useEffect
			assignOutputToChart2();

			// SIMPLIFIED METHODS
			assignOutputSimplified();
			assignOutputToSimplifiedChart();
			// getTotalRowSimplified();other useEffect

			assignOutputToSimplifiedChart2();
			assignOutputToSimplifiedChart3();
			// assignOutputToFigureSpiderChart();

			// nextStep();
		}
	}, [output, valueDisplayOption, decimalsOption]);

	//  TOTALS FOR SIMPLIFIED AND COMPLETE DATA
	useEffect(() => {
		if (segmentationItemValues.length > 0) {
			getTotalRow();
			setFinish(true);
		}
	}, [segmentationItemValues]);

	useEffect(() => {
		if (simplifiedSegmentationItemValues.length > 0) {
			getTotalRowSimplified();
			setFinish(true);
		}
	}, [simplifiedSegmentationItemValues]);

	useEffect(() => {
		// console.log('header', headerTable);
		// console.log('total', totals);
	}, [finish]);

	useEffect(() => {
		if (loadingStep < loadingSteps) {
			const timer = setTimeout(() => {
				setLoadingStep((prevStep) => prevStep + 1);
			}, 100);
			return () => clearTimeout(timer);
		} else {
			setLoadingFinish(true);
		}
	}, [loadingStep]);

	// simplified Charts,Tables Method

	const compareAbrevition = (abrev) => {
		// console.log(abrev, arrayDesc);
		for (let subArray of arrayDesc) {
			for (let item of subArray) {
				if (item.name === abrev) {
					return item;
				}
			}
		}
		return { name: abrev, description: null };
	};

	const assignOutput = () => {
		let years = [""];
		output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.forEach(
			(item) => years.push(item.year)
		);

		const fyString = years[1].toString();
		const lyString = years[years.length - 1].toString();
		let sumHeader = "∑" + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(sumHeader);

		// let cagr = 'CAGR <br />' + fyString.slice(-2) + '-' + lyString.slice(-2);
		let cagr = "CAGR" + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(cagr);

		setHeaderTable([years]);

		let newSegmentationTitles = [];
		let newSegmentationItemValues = [];
		let newMaxValue = [];

		output.forEach((item, index) => {
			let titles = [];
			const segmentationName = item.segmentations[0].name;
			titles.push([segmentationName]);
			newSegmentationTitles.push(titles);

			const versusValueFinal = [];
			item.segmentations[0].segmentationItems.forEach((segmItem) => {
				let segmItemName;
				const versusValue = [];
				if (segmItem.regionId != null) {
					segmItemName = {
						name: segmItem.region.name,
						description: null,
					};
				} else {
					segmItemName = compareAbrevition(segmItem.name); // segmItem.name;
				}
				// console.log("versusValue", versusValue);
				versusValue.push(segmItemName);
				// console.log("versusValue", versusValue);

				segmItem.documentProgramTopic.documentProgramValues.forEach(
					(valueItem) => {
						if (!newMaxValue[index]) {
							newMaxValue[index] = valueItem.value;
						} else {
							if (valueItem.value > newMaxValue[index]) {
								newMaxValue[index] = valueItem.value;
							}
						}
						versusValue.push(
							tofixValueOptionDecimate(valueItem.value)
						);
					}
				);

				let sumArray = [...versusValue];
				sumArray.shift();
				let sum = toFixDecimate3(
					sumArray.reduce((acc, val) => acc + parseFloat(val), 0)
				);
				versusValue.push(sum);

				let cagr;
				if (segmItem.documentProgramTopic.cagr !== null) {
					cagr = segmItem.documentProgramTopic.cagr.toFixed(1) + "%";
				} else {
					cagr = 0 + "%";
				}
				versusValue.push(cagr);

				versusValueFinal.push(versusValue);
			});
			newSegmentationItemValues.push(versusValueFinal);
		});

		setSegmentationTitles(newSegmentationTitles);
		setSegmentationItemValues(newSegmentationItemValues);
		setMaxValue(newMaxValue);
	};

	const assignOutputToChart = () => {
		let years = [];
		let outputViews = [];

		// outputYears SHOW ARRAY OF YEARS ['2024', '2025', '2026']
		output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.forEach(
			(item) => years.push(item.year)
		);
		outputViews.push(years);
		setToChartYears(outputViews);

		// outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
		let outputData = [];
		output.forEach((item, index) => {
			const versusValueFinal = [];
			item.segmentations[0].segmentationItems.forEach((segmItem) => {
				const versusValue = [];
				segmItem.documentProgramTopic.documentProgramValues.forEach(
					(item2) => {
						versusValue.push(tofixValueOptionDecimate(item2.value));
					}
				);
				versusValueFinal.push(versusValue);
			});
			outputData.push([versusValueFinal]);
		});

		// outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN
		let outputDataFinalle = [];
		outputData.forEach((output) => {
			let bData = [];
			output.forEach((item) => {
				let outputArray = [];
				outputViews[0].forEach((year, index) => {
					let aData = [];
					item.forEach((data) => {
						aData.push(data[index]);
					});
					outputArray.push(aData);
				});
				bData.push(outputArray);
			});
			outputDataFinalle.push(bData);
		});
		setToChartData(outputDataFinalle);

		// SegmItemsRival
		// SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1', 'SEGMENTATION2', 'SEGMENTATION3']
		let segmItemsArray = [];
		output.forEach((item) => {
			let segmItems = [];
			item.segmentations[0].segmentationItems.forEach((segmItem) => {
				if (segmItem.regionId != null) {
					segmItems.push(segmItem.region.name);
				} else {
					segmItems.push(segmItem.name);
				}
			});
			segmItemsArray.push(segmItems);
		});

		setToChartSegmItems(segmItemsArray);
	};

	const getTotalRow = () => {
		// CLONE DEEP ARRAY
		let segmentationItemValuesClone = JSON.parse(
			JSON.stringify(segmentationItemValues)
		);

		//GET TOTAL
		let newTotals = segmentationItemValuesClone.map((segmItem) => {
			let b = [];
			segmItem.forEach((si) => {
				if (b.length === 0) {
					let totArray = [];
					si.forEach((s, index) => {
						if (index === 0) {
							totArray.push("Total");
						} else {
							if (index !== si.length - 1) {
								if (typeof s !== "number") {
									let parseToNumber = Number(s);
									totArray.push(parseToNumber);
								} else {
									totArray.push(s);
								}
							}
						}
					});
					b.push(totArray);
				} else {
					si.forEach((s, index2) => {
						if (index2 !== 0 && index2 !== si.length - 1) {
							if (typeof s !== "number") {
								let parseToNumber = Number(s);
								b[0][index2] += parseToNumber;
							} else {
								b[0][index2] += s;
							}
						}
					});
				}
			});

			let c = [];
			const d = b[0].map((item, index) => {
				if (index !== 0) {
					return toFixDecimate3(item);
				} else {
					return item;
				}
			});

			// add CAGR to total row
			const cagrValue = calculateCAGR(d).toFixed(1) + "%";

			d.push(cagrValue);

			// add d array to c
			c.push(d);

			return c;
		});
		setTotals(newTotals);
	};

	const assignOutputToChart2 = () => {
		const versusValueFinalYear = [];

		output[0].segmentations[0].segmentationItems.forEach((segmItem) => {
			let segmItemName;

			if (segmItem.regionId != null) {
				segmItemName = segmItem.region.name;
			} else {
				segmItemName = segmItem.name;
			}

			let valueFirstYear = [
				segmItemName, // segmName
				tofixValueOptionDecimate(
					segmItem.documentProgramTopic.documentProgramValues[0].value
				), // FirstYear
				tofixValueOptionDecimate(
					segmItem.documentProgramTopic.documentProgramValues[
						segmItem.documentProgramTopic.documentProgramValues
							.length - 1
					].value
				), // LastYear
				// TODO SUM
			];
			versusValueFinalYear.push(valueFirstYear);
		});

		setToFigure3ChartDataYear([versusValueFinalYear]);
		setToFigure3ChartFirstYear(
			output[0].segmentations[0].segmentationItems[0].documentProgramTopic
				.documentProgramValues[0].year
		);
		setToFigure3ChartLastYear(
			output[0].segmentations[0].segmentationItems[0].documentProgramTopic
				.documentProgramValues[
				output[0].segmentations[0].segmentationItems[0]
					.documentProgramTopic.documentProgramValues.length - 1
			].year
		);
	};

	// simplified Charts,Tables Method

	const assignOutputSimplified = () => {
		let years = [""];
		let newYears = [];
		output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(
			(item) => newYears.push(item.year)
		);

		const firstElement = newYears[0];
		const lastElement = newYears[newYears.length - 1];

		years.push(firstElement);
		years.push(lastElement);

		const fyString = firstElement.toString();
		const lyString = lastElement.toString();

		let sumHeader = "∑" + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(sumHeader);

		let cagr = "CAGR " + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(cagr);

		setSimplifiedHeaderTable([years]);

		let newSegmentationTitles = [];
		let newSegmentationItemValues = [];
		let newMaxValueFigure1 = [...maxValueFigure1];

		for (let index = 0; index < output.length; index++) {
			let titles = [];
			const segmentationName = output[index].segmentations[0].name;
			titles.push([segmentationName]);
			newSegmentationTitles.push(titles);

			const versusValueFinal = [];
			output[index].segmentations[0].segmentationItems.forEach((item) => {
				// TODO compare with arrayDesc
				let segmItemName =
					item.regionId != null
						? { name: item.region.name, description: null }
						: compareAbrevition(item.name);
				const versusValue = [segmItemName];

				const dataValues =
					item.documentProgramTopic.documentProgramValues;

				const firstYearData = dataValues[0];
				versusValue.push(tofixValueOptionDecimate(firstYearData.value));

				const lastYearData = dataValues[dataValues.length - 1];
				versusValue.push(tofixValueOptionDecimate(lastYearData.value));

				if (
					!newMaxValueFigure1[index] ||
					firstYearData.value > newMaxValueFigure1[index]
				) {
					newMaxValueFigure1[index] = firstYearData.value;
				}
				if (
					!newMaxValueFigure1[index] ||
					lastYearData.value > newMaxValueFigure1[index]
				) {
					newMaxValueFigure1[index] = lastYearData.value;
				}

				let sum = 0;
				item.documentProgramTopic.documentProgramValues.forEach(
					(item) => {
						let converted = tofixValueOptionDecimate(item.value);
						sum += parseFloat(converted);
					}
				);

				versusValue.push(toFixDecimate3(sum));

				let cagrValue;
				if (item.documentProgramTopic.cagr !== null) {
					cagrValue = item.documentProgramTopic.cagr.toFixed(1) + "%";
				} else {
					cagrValue = "0%";
				}

				versusValue.push(cagrValue);
				versusValueFinal.push(versusValue);
			});

			newSegmentationItemValues.push(versusValueFinal);
		}

		setSimplifiedSegmentationTitles(newSegmentationTitles);
		setSimplifiedSegmentationItemValues(newSegmentationItemValues);
		setMaxValueFigure1(newMaxValueFigure1);
	};

	const assignOutputToSimplifiedChart = () => {
		let newToFigure1SegmItems = [];
		let newYears = [];

		for (let index = 0; index < output.length; index++) {
			const versusValueFinal = [];
			output[index].segmentations[0].segmentationItems.map((item) => {
				let segmItemName;
				const versusValue = [];
				if (item.regionId != null) {
					segmItemName = item.region.name;
				} else {
					segmItemName = item.name;
				}
				versusValue.push(segmItemName);

				const dataValues =
					item.documentProgramTopic.documentProgramValues;

				const firstYearData = dataValues[0];
				versusValue.push(tofixValueOptionDecimate(firstYearData.value));

				const lastYearData = dataValues[dataValues.length - 1];
				versusValue.push(tofixValueOptionDecimate(lastYearData.value));

				let cagrFormula;
				if (item.documentProgramTopic.cagr !== null) {
					cagrFormula =
						item.documentProgramTopic.cagr.toFixed(1) + "%";
				} else {
					cagrFormula = "0%";
				}

				versusValue.push(cagrFormula);
				versusValueFinal.push(versusValue);
			});
			newToFigure1SegmItems.push(versusValueFinal);
		}

		output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(
			(item) => newYears.push(item.year)
		);

		const firstElement = newYears[0];
		const lastElement = newYears[newYears.length - 1];

		setToFigure1SegmItems(newToFigure1SegmItems);
		setToFigure1ChartYears([firstElement, lastElement]);
	};

	const getTotalRowSimplified = () => {
		let segmentationItemValuesClone = JSON.parse(
			JSON.stringify(simplifiedSegmentationItemValues)
		);

		let newSimplifiedTotals = [];

		segmentationItemValuesClone.map((segmItem) => {
			let b = [];
			segmItem.map((si) => {
				if (b.length === 0) {
					let totArray = [];
					si.map((s, index) => {
						if (index === 0) {
							totArray.push("Total");
						} else {
							if (index !== si.length - 1) {
								if (typeof s !== "number") {
									let parseToNumber = Number(s);
									totArray.push(parseToNumber);
								} else {
									totArray.push(s);
								}
							}
						}
					});
					b.push(totArray);
				} else {
					si.map((s, index2) => {
						if (index2 !== 0 && index2 !== si.length - 1) {
							if (typeof s !== "number") {
								let parseToNumber = Number(s);
								b[0][index2] += parseToNumber;
							} else {
								b[0][index2] += s;
							}
						}
					});
				}
			});

			let c = [];
			const d = b[0].map((item, index) => {
				if (index !== 0) {
					return toFixDecimate3(item);
				} else {
					return item;
				}
			});

			// add cagr to total row
			const cagrValue = calculateCAGRSimplified(d).toFixed(1) + "%";
			d.push(cagrValue);

			// add D Array to c
			c.push(d);

			newSimplifiedTotals.push(c);
		});

		setSimplifiedTotals(newSimplifiedTotals);
	};

	const assignOutputToSimplifiedChart2 = () => {
		let newToFigure2ChartData = [];

		for (let index = 0; index < output.length; index++) {
			const versusValueFinal = [];

			output[index].segmentations[0].segmentationItems.map((item) => {
				let segmItemName;
				const versusValue = [];

				if (item.regionId != null) {
					segmItemName = item.region.name;
				} else {
					segmItemName = item.name;
				}
				versusValue.push(segmItemName);

				let sum = 0;
				item.documentProgramTopic.documentProgramValues.map((item) => {
					let converted = tofixValueOptionDecimate(item.value);
					sum += parseFloat(converted);
				});

				versusValue.push(toFixDecimate3(sum));
				versusValueFinal.push(versusValue);
			});

			newToFigure2ChartData.push(versusValueFinal);
		}

		setToFigure2ChartData(newToFigure2ChartData);
	};

	const assignOutputToSimplifiedChart3 = () => {
		let newToFigureSpiderData = [];

		for (let index = 0; index < output.length; index++) {
			const versusValueFinal = [];

			output[index].segmentations[0].segmentationItems.map((item) => {
				let segmItemName;
				const versusValue = [];

				if (item.regionId != null) {
					segmItemName = item.region.name;
				} else {
					segmItemName = item.name;
				}

				versusValue.push(segmItemName);

				let cagrFormula;
				if (item.documentProgramTopic.cagr !== null) {
					cagrFormula = item.documentProgramTopic.cagr.toFixed(1);
				} else {
					cagrFormula = 0;
				}

				versusValue.push(cagrFormula);
				versusValueFinal.push(versusValue);
			});

			newToFigureSpiderData.push(versusValueFinal);
		}
		setToFigureSpiderData(newToFigureSpiderData);
	};

	// HELPERS
	const toFixDecimate3 = (cell) => {
		if (decimalsOption === "0.") {
			if (Number.isInteger(cell)) {
				return cell;
			} else {
				return Math.round(cell);
			}
		}
		if (decimalsOption === ".0") {
			return roundTo(cell, 1).toFixed(1);
		}
		if (decimalsOption === ".00") {
			return roundTo(cell, 2).toFixed(2);
		}
		if (decimalsOption === ".000") {
			return roundTo(cell, 3).toFixed(3);
		}
	};

	const tofixValueOptionDecimate = (data) => {
		const valueDisplay = convertValueDisplayOption(data);
		return toFixDecimate3(valueDisplay);
	};

	const convertValueDisplayOption = (data) => {
		if (valueDisplayOption === "billions") {
			return data / 1000;
		} else {
			return data;
		}
	};

	const roundTo = (num, decimals) => {
		let factor = Math.pow(10, decimals);
		return Math.round(num * factor) / factor;
	};

	const calculateCAGR = (values) => {
		let val = [...values];
		val.shift();
		val.pop();

		let initialValue = val[0];
		let finalValue = val[val.length - 1];
		let n = val.length - 1;

		const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
		return cagr ? cagr * 100 : 0;
	};

	const calculateCAGRSimplified = (values) => {
		let val = [...values];
		val.shift();
		val.pop();

		let initialValue = val[0];
		let finalValue = val[val.length - 1];
		//  using toFigure1ChartYears for getting  years sub
		let n = toFigure1ChartYears[1] - toFigure1ChartYears[0];

		const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
		return cagr ? cagr * 100 : 0;
	};

	const formatNumber = (num) => {
		if (num) {
			// split int and decimals of number
			let [int, decimal] = num.toString().split(".");

			//format int with ,
			int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return decimal ? `${int}.${decimal}` : int;
		} else {
			return num;
		}
	};

	const compareFYFL = (fy, fl) => {
		const fyTotal = fy.reduce((acc, value) => acc + parseFloat(value), 0);
		const flTotal = fl.reduce((acc, value) => acc + parseFloat(value), 0);

		const totalFY = fyTotal;
		const totalLY = flTotal;

		return totalFY > totalLY ? true : false;
	};
	return (
		<div className="row">
			{finish ? (
				<>
					<div className="col-12 col-lg-8 mt-4">
						<h4 id="chapter1">
							1. {topicName} Market by{" "}
							{segmentationTitles[0][0][0]} Overview
						</h4>
					</div>

					<div className="col-12 col-lg-8 mt-2">
						<hr></hr>
						<h6>
							Table 1.1 {topicName} Market Forecast to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 1 ? (
							<table className="table caption-top table-bordered">
								{/* headerTable */}
								<thead>
									<tr className="bg-light text-dark">
										{simplifiedHeaderTable[0].map(
											(
												simplifiedHeader,
												simplifiedHeaderIndex
											) =>
												simplifiedHeaderIndex === 0 ? (
													<th
														key={
															simplifiedHeaderIndex
														}
													></th>
												) : (
													<th
														className="text-end"
														scope="col"
														key={
															"simplifiedHeader " +
															simplifiedHeaderIndex
														}
													>
														{simplifiedHeader}
													</th>
												)
										)}
									</tr>
								</thead>
								<tbody>
									{/* data */}
									{simplifiedSegmentationItemValues[0].map(
										(row, rowIndex) => (
											<tr key={rowIndex}>
												{row.map((cell, cellIndex) => (
													<td
														className={
															cellIndex === 0
																? "text-start"
																: "text-end"
														}
														key={
															"simplifiedCell" +
															cellIndex
														}
													>
														{cellIndex === 0 ? (
															<>
																{cell.description ===
																null ? (
																	cell.name
																) : (
																	<abbr
																		title={
																			cell.description
																		}
																	>
																		{
																			cell.name
																		}
																	</abbr>
																)}
															</>
														) : (
															formatNumber(cell)
														)}
													</td>
												))}
											</tr>
										)
									)}
								</tbody>
								<tfoot>
									{/* total */}
									{simplifiedTotals[0].map(
										(total, totalIndex) => (
											<tr
												key={totalIndex}
												className="bg-light"
											>
												{total.map(
													(cell, cellIndex) => (
														<td
															className="text-end"
															key={
																"simplifiedTotal" +
																cellIndex
															}
														>
															{cellIndex === 0 ? (
																<strong>
																	{cell}
																</strong>
															) : (
																formatNumber(
																	cell
																)
															)}
														</td>
													)
												)}
											</tr>
										)
									)}
								</tfoot>
							</table>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr></hr>
						<h6>
							Figure 1.1 {topicName} Market Summary to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 2 ? (
							<AnyChartSimplifiedFigure1
								years={toFigure1ChartYears}
								data={toFigure1SegmItems[0]}
								maxValue={convertValueDisplayOption(
									maxValue[0]
								)}
								valueDisplayOption={valueDisplayOption}
								topicName={topicName}
								segmentationName={segmentationTitles[0][0][0]}
							/>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr></hr>
						<h6>
							Figure 1.2 {topicName} Market Size to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 3 ? (
							<AnyChartSimplifiedFigure2
								years={toFigure1ChartYears}
								data={toFigure2ChartData[0]}
								valueDisplayOption={valueDisplayOption}
								topicName={topicName}
								segmentationName={segmentationTitles[0][0][0]}
							/>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr></hr>
						<h6>
							Figure 1.3 {topicName} Market CAGR to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 4 ? (
							<AnyChartSimplifiedFigure3
								years={toFigure1ChartYears}
								data={toFigureSpiderData[0]}
								topicName={topicName}
								segmentationName={segmentationTitles[0][0][0]}
							/>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr></hr>
						<h6>
							Table 1.2 {topicName} Market Forecast to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 5 ? (
							<table className="table caption-top table-bordered">
								<thead>
									<tr className="bg-light text-dark">
										{headerTable[0].map(
											(header, headerIndex) =>
												headerIndex === 0 ? (
													<th key={headerIndex}></th>
												) : (
													<th
														className="text-end"
														key={headerIndex}
													>
														{header}
													</th>
												)
										)}
									</tr>
								</thead>
								<tbody>
									{segmentationItemValues[0].map(
										(row, rowIndex) => (
											<tr
												key={rowIndex}
												className={
													rowIndex === 0
														? "bg-light "
														: ""
												}
											>
												{row.map((cell, cellIndex) => (
													<td
														key={
															"cell " + cellIndex
														}
														className={
															cellIndex === 0
																? ""
																: "text-end"
														}
													>
														{cellIndex === 0 ? (
															<>
																{cell.description ===
																null ? (
																	cell.name
																) : (
																	<abbr
																		title={
																			cell.description
																		}
																	>
																		{
																			cell.name
																		}
																	</abbr>
																)}
															</>
														) : (
															formatNumber(cell)
														)}
													</td>
												))}
											</tr>
										)
									)}
								</tbody>
								<tfoot>
									{totals[0].map((total, totalIndex) => (
										<tr
											key={totalIndex}
											className="bg-light"
										>
											{total.map((cell, cellIndex) => (
												<td
													key={"total " + cellIndex}
													className="text-end"
												>
													{formatNumber(cell)}
												</td>
											))}
										</tr>
									))}
								</tfoot>
							</table>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr></hr>
						<h6>
							Figure 1.4 {topicName} Market CAGR to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{loadingStep >= 6 ? (
							<AnyChartFigure1
								years={toChartYears[0]}
								data={toChartData[0][0]}
								total={totals[0][0]}
								segmentationItems={toChartSegmItems[0]}
								output={output}
								segmentationVersusTitles={
									"Single Segmentation Title"
								}
								topicName={topicName}
								segmentationName={segmentationTitles[0][0][0]}
								valueDisplayOption={valueDisplayOption}
							/>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>

					<div className="col-12 col-lg-8 mt-4">
						<hr />
						<h6>
							Figure 1.5 {topicName} Market CAGR to{" "}
							{toFigure1ChartYears[1]} by{" "}
							{segmentationTitles[0][0][0]} US${" "}
							{valueDisplayOption}
						</h6>
						{/* <AnyChartFigure2
							data={toFigure3ChartDataYear[0]}
							year={toFigure3ChartFirstYear}
							firstYear={toFigure3ChartFirstYear}
							lastYear={toFigure3ChartLastYear}
							topicName={topicName}
							segmentationName={segmentationTitles[0][0][0]}
							valueDisplayOption={valueDisplayOption}
							FYValues={toChartData[0][0][0]}
							LYValues={
								toChartData[0][0][toChartData[0][0].length - 1]
							}
						/> */}
						{loadingStep >= 7 ? (
							<div
								style={{
									width: "1400px",
									height: "500px",
								}}
							>
								{compareFYFL(
									toChartData[0][0][0],
									toChartData[0][0][
										toChartData[0][0].length - 1
									]
								) ? (
									<div
										style={{
											height: "100%",
											width: "100%",
											display: "flex",
										}}
									>
										<div
											style={{
												width: "55%",
												height: "100%",
											}}
										>
											{/* <h1>{{toFigure3ChartFirstYear}}</h1> */}
											<AnychartSimplifiedPieChart
												data={toFigure3ChartDataYear[0]}
												year={toFigure3ChartFirstYear}
												firstYear={
													toFigure3ChartFirstYear
												}
												lastYear={
													toFigure3ChartLastYear
												}
												topicName={topicName}
												segmentationName={
													segmentationTitles[0][0][0]
												}
												valueDisplayOption={
													valueDisplayOption
												}
												FYValues={toChartData[0][0][0]}
												yearIndex={1}
												decimalsOption={decimalsOption}
											/>
										</div>
										<div
											style={{
												width: "45%",
												height: "100%",
											}}
										>
											<AnychartSimplifiedPieChart
												data={toFigure3ChartDataYear[0]}
												year={toFigure3ChartFirstYear}
												firstYear={
													toFigure3ChartFirstYear
												}
												lastYear={
													toFigure3ChartLastYear
												}
												topicName={topicName}
												segmentationName={
													segmentationTitles[0][0][0]
												}
												valueDisplayOption={
													valueDisplayOption
												}
												LYValues={
													toChartData[0][0][
														toChartData[0][0]
															.length - 1
													]
												}
												yearIndex={2}
												decimalsOption={decimalsOption}
											/>
										</div>
									</div>
								) : (
									<div
										style={{
											height: "100%",
											width: "100%",
											display: "flex",
										}}
									>
										<div
											style={{
												width: "45%",
												height: "100%",
											}}
										>
											<AnychartSimplifiedPieChart
												data={toFigure3ChartDataYear[0]}
												year={toFigure3ChartFirstYear}
												firstYear={
													toFigure3ChartFirstYear
												}
												lastYear={
													toFigure3ChartLastYear
												}
												topicName={topicName}
												segmentationName={
													segmentationTitles[0][0][0]
												}
												valueDisplayOption={
													valueDisplayOption
												}
												FYValues={toChartData[0][0][0]}
												yearIndex={1}
												decimalsOption={decimalsOption}
											/>
										</div>

										<div
											style={{
												width: "55%",
												height: "100%",
											}}
										>
											<AnychartSimplifiedPieChart
												data={toFigure3ChartDataYear[0]}
												year={toFigure3ChartFirstYear}
												firstYear={
													toFigure3ChartFirstYear
												}
												lastYear={
													toFigure3ChartLastYear
												}
												topicName={topicName}
												segmentationName={
													segmentationTitles[0][0][0]
												}
												valueDisplayOption={
													valueDisplayOption
												}
												LYValues={
													toChartData[0][0][
														toChartData[0][0]
															.length - 1
													]
												}
												yearIndex={2}
												decimalsOption={decimalsOption}
											/>
										</div>
									</div>
								)}
							</div>
						) : (
							<h3>
								{/* TODO change loading */}
								<div>
									<div className="row mt-1">
										<div
											className="spinner-border gutter-none m-5"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									</div>
									<div className="row">
										<span className="text-primary">
											Loading data...
										</span>
									</div>
								</div>
							</h3>
						)}
					</div>
				</>
			) : (
				<div>
					<div className="row mt-1">
						<div className="spinner-border m-5" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
					<div className="row">
						<span className="text-primary">Loading data...</span>
					</div>
				</div>
			)}
		</div>
	);
};
